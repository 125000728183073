<template>
  <span v-if="!link" :class="classes">
    <slot />
  </span>
  <NuxtLink :to="link" v-if="link" :class="classes">
    <slot />
  </NuxtLink>
</template>

<script setup>
  defineProps(["link"]);
  var classes = computed(()=>{
    return "fs-3 ms-3 text-decoration-none text-primary align-text-top text-nowrap";
  });
</script>